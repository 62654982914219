<template>
  <v-container>
    <v-autocomplete
      :items="batchRooms"
      label="Select Room"
      v-model="selectedBatchRoom"
      item-value="id"
      item-text="room__name"
    >
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title v-html="item.room__name"></v-list-item-title>
          <v-list-item-subtitle>
            {{ item.workshop__title }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ item.days }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template></v-autocomplete
    >
    <v-select
      label="Payment Methods"
      v-model="payment_method"
      :items="paymentMethods"
    ></v-select>
    <v-text-field :label="paymentMethodText" v-model="payment_transaction_id">
    </v-text-field>
    <v-text-field type="number" label="Amount" v-model="amount"> </v-text-field>
    <v-text-field label="Email" v-model="email"> </v-text-field>
    <v-text-field label="First Name" v-model="firstName"> </v-text-field>
    <v-text-field label="Last Name" v-model="lastName"> </v-text-field>
    <phone-field v-model="countryPhone"></phone-field>

    <v-btn width="100%" color="primary" @click="submit">Submit</v-btn>
  </v-container>
</template>

<script>
import PhoneField from "@/components/global/PhoneField.vue";
export default {
  components: { PhoneField },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      amount: "",
      payment_transaction_id: "",
      countryPhone: "",
      payment_method: "",
      paymentMethods: [
        {
          text: "Cash",
          value: "cash",
        },
        {
          text: "Card",
          value: "card",
        },
        {
          text: "Bkash",
          value: "bkash",
        },
        {
          text: "Rocket",
          value: "rocket",
        },
      ],
      batchRooms: [],
    };
  },
  computed: {
    paymentMethodText() {
      return this.payment_method
        ? `${
            this.paymentMethods.find(
              (item) => item.value === this.payment_method
            ).text
          } Transaction ID`
        : "Transaction ID";
    },
  },
  mounted() {
    this.loadBatchRooms();
  },
  methods: {
    loadBatchRooms() {
      return this.$axios.get("/workshops/batch-rooms/").then((response) => {
        this.batchRooms = response.data;
      });
    },
    submit() {
      return this.$axios
        .post("/workshops/single-user-entry/", {
          first_name: this.firstName,
          last_name: this.lastName,
          phone: this.countryPhone.phone,
          country: this.countryPhone.country_id,
          email: this.email,
          batch_room: this.selectedBatchRoom,
          amount: this.amount,
          manual_payment: {
            payment_method: this.payment_method,
            payment_transaction_id: this.payment_transaction_id,
          },
        })
        .then(() => alert("Success"))
        .catch((err) => alert(err.response.data));
    },
  },
};
</script>

<style>
</style>