<template>
  <v-form ref="phoneFieldForm">
    <v-row class="d-flex-inline">
      <v-col cols="5" sm="5" lg="4">
        <v-select
          @input="handleInput"
          :items="countries"
          v-model="country"
          :rules="requiredRules"
          :readonly="readonly"
          return-object
        >
          <template #item="{ item }">
            <v-img width="32" height="16" v-html="item.flag"></v-img>
            <span class="mx-2"> {{ item.name }} </span>
          </template>
          <template #selection="{ item }">
            <v-img width="32" height="16" v-html="item.flag"></v-img>

            <!-- <v-icon class="mx-2"> {{ item.icon }} </v-icon
            > -->
            <span class="mx-2"> {{ item.country_code }} </span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="7" sm="7" lg="8" class="mx-md-0 pl-md-0">
        <v-text-field
          @input="handleInput"
          v-model="phone"
          :rules="phoneRules"
          label="Phone"
          :readonly="readonly"
        ></v-text-field>
      </v-col> </v-row
  ></v-form>
</template>

<script>
export default {
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      phoneFieldForm: "",
      country: "",
      country_code: "",
      country_id: "",
      // countryCode: "+88",
      requiredRules: [(v) => !!v || "This Field is Required"],
      countries: [],
      phone: "",
    };
  },

  computed: {
    phoneRules() {
      return [
        ...this.rules,
        (v) => !!v || "Phone is required",
        (v) => /^\d{11}$/.test(v) || "Phone must be 11 digits",
      ];
    },
  },
  created() {
    this.loadCountries();
  },
  methods: {
    loadCountries() {
      return this.$api
        .get("/locations/countries/")
        .then((res) => {
          this.countries = res.data.results;
        })
        .then(() => (this.country = this.countries[0]));
    },
    handleInput() {
      this.$emit("input", {
        country_code: this.country.country_code,
        country_id: this.country.id,
        phone: this.phone,
      });
    },
    validate() {
      return this.$refs.phoneFieldForm.validate();
    },
  },
};
</script>

<style scoped>
</style>
